import { inject, Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import {
  Mutation,
  MutationCreateTaskScheduleArgs,
  MutationDeleteTaskScheduleArgs,
  MutationUpdateTaskScheduleArgs,
  Query,
  QueryFindManyTaskSchedulesArgs,
  QueryFindUniqueTaskScheduleArgs,
  TaskSchedule,
} from '../generated';
import { RcApiService } from './rc-api.service';

const FIND_MANY_TASK_SCHEDULES = gql`
  query FindManyTaskSchedules(
    $where: TaskScheduleWhereInput
    $orderBy: [TaskScheduleOrderByWithRelationInput!]
    $cursor: TaskScheduleWhereUniqueInput
    $take: Int
    $skip: Int
    $distinct: [TaskScheduleScalarFieldEnum!]
  ) {
    findManyTaskSchedules(
      where: $where
      orderBy: $orderBy
      cursor: $cursor
      take: $take
      skip: $skip
      distinct: $distinct
    ) {
      id
      title
      enabled
      rrule
      description
      categories
      details
      resources
      createdAt
      updatedAt
      dueIn
      assignee
      tasks {
        id
        title
        description
        categories
        details
        resources
        status
        createdAt
        dueAt
        updatedAt
        completedAt
        assignee
        unit {
          id
          name
          property {
            id
            address
          }
          tenants {
            name
          }
        }
      }
      units {
        id
        name
        property {
          id
          address
        }
        tenants {
          name
        }
      }
    }
  }
`;

const FIND_UNIQUE_TASK_SCHEDULE = gql`
  query FindUniqueTaskSchedule($where: TaskScheduleWhereUniqueInput!) {
    findUniqueTaskSchedule(where: $where) {
      id
      title
      enabled
      rrule
      description
      categories
      details
      resources
      createdAt
      updatedAt
      dueIn
      assignee
      tasks {
        id
        title
        description
        categories
        details
        resources
        status
        createdAt
        dueAt
        updatedAt
        completedAt
        assignee
        unit {
          id
          name
          property {
            id
            address
          }
          tenants {
            name
          }
        }
      }
      units {
        id
        name
        property {
          id
          address
        }
        tenants {
          name
        }
      }
    }
  }
`;

const TASK_SCHEDULE_REFETCH_LIST = [
  FIND_MANY_TASK_SCHEDULES,
  FIND_UNIQUE_TASK_SCHEDULE,
];

@Injectable()
export class TaskScheduleService {
  private readonly rcApiService = inject(RcApiService);

  public findMany(
    args?: QueryFindManyTaskSchedulesArgs,
  ): Observable<TaskSchedule[]> {
    return this.rcApiService.apollo
      .watchQuery<Query>({
        query: FIND_MANY_TASK_SCHEDULES,
        variables: args,
        pollInterval: 5000,
      })
      .valueChanges.pipe(map((result) => result.data.findManyTaskSchedules));
  }

  public findOne(
    args: QueryFindUniqueTaskScheduleArgs,
  ): Observable<TaskSchedule> {
    return this.rcApiService.apollo
      .watchQuery<Query>({
        query: FIND_UNIQUE_TASK_SCHEDULE,
        variables: args,
        pollInterval: 5000,
      })
      .valueChanges.pipe(map((result) => result.data.findUniqueTaskSchedule));
  }

  public create(
    args: MutationCreateTaskScheduleArgs,
  ): Observable<TaskSchedule> {
    return this.rcApiService.apollo
      .mutate<Mutation>({
        mutation: gql`
          mutation CreateTaskSchedule($data: TaskScheduleCreateInput!) {
            createTaskSchedule(data: $data) {
              id
              title
              enabled
              rrule
              description
              categories
              details
              resources
              createdAt
              updatedAt
              assignee
              units {
                id
                name
                property {
                  id
                  address
                }
                tenants {
                  name
                }
              }
            }
          }
        `,
        variables: args,
        refetchQueries: TASK_SCHEDULE_REFETCH_LIST,
      })
      .pipe(map((result) => result.data!.createTaskSchedule));
  }

  public update(
    args: MutationUpdateTaskScheduleArgs,
  ): Observable<TaskSchedule> {
    return this.rcApiService.apollo
      .mutate<Mutation>({
        mutation: gql`
          mutation UpdateTaskSchedule(
            $data: TaskScheduleUpdateInput!
            $where: TaskScheduleWhereUniqueInput!
          ) {
            updateTaskSchedule(data: $data, where: $where) {
              id
              title
              enabled
              rrule
              description
              categories
              details
              resources
              createdAt
              updatedAt
              assignee
              units {
                id
                name
                property {
                  id
                  address
                }
                tenants {
                  name
                }
              }
            }
          }
        `,
        variables: args,
        refetchQueries: TASK_SCHEDULE_REFETCH_LIST,
      })
      .pipe(map((result) => result.data!.updateTaskSchedule));
  }

  public delete(
    args: MutationDeleteTaskScheduleArgs,
  ): Observable<TaskSchedule> {
    return this.rcApiService.apollo
      .mutate<Mutation>({
        mutation: gql`
          mutation DeleteTaskSchedule($where: TaskScheduleWhereUniqueInput!) {
            deleteTaskSchedule(where: $where) {
              id
              title
              enabled
              rrule
              description
              categories
              details
              resources
              createdAt
              updatedAt
              assignee
              units {
                id
                name
                property {
                  id
                  address
                }
                tenants {
                  name
                }
              }
            }
          }
        `,
        variables: args,
        refetchQueries: TASK_SCHEDULE_REFETCH_LIST,
      })
      .pipe(map((result) => result.data!.deleteTaskSchedule));
  }
}
